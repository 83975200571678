<template>
    <div class="input-group z-index-input">
        <div class="input-group-prepend">
            <span class="input-group-text"><i :class="'fal fa-' + field.icon" /></span>
        </div>
        <component :is=cmpn v-model="message"></component>
    </div>
</template>

<script>

import { h, computed, ref } from "vue";
import {useI18n} from "vue-i18n";
import InputText from 'primevue/inputtext';
import Dropdown from 'primevue/dropdown';
import SchemaAutoComplete from '@/components/widgets/SchemaAutoComplete';
import RangeDate from '@/components/widgets/RangeDate';
import RangeInteger from '@/components/widgets/RangeInteger';
import Calendar from 'primevue/calendar';
import InputNumber from 'primevue/inputnumber';

import { readableDate } from '@/mixins/filters';

/*
*  @group Widgets
*  SchemaField is used to generate field for a form (`ModelForm`) based on schema
*/
export default {
    name: "SchemaField",
    props: {
        // Object form schema that define this field
        'field': {
            type: Object,
            required: true,
        },
        // Name of the field
        'name': {
            type: String,
            required: false,
        },
        // v-model attribute of this component
        'modelValue': {
            type: [Object, String, Number],
            required: false,
        },
        // whether this field is used in a search form or not
        'searchmode': {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    emits: ['update:modelValue'],
    components: {
    },

    setup(props, { emit }) {
        const i18n = useI18n();
        // message is the vue 'model' attribute of this Schema field
        const message = computed({
            get: function() {
                if (props.field.type === 'datetime' && !props.searchmode && props.modelValue) {
                    // compare with actual value
                    // if field is a date, convert it to human readable text
                    if (props.field.write) {
                        return new Date(props.modelValue);
                    }
                    return readableDate(props.modelValue);
                } else if (props.field.type === 'number' && !props.field.write && !props.field.searchmode) {
                    let v = parseFloat(props.modelValue);
                    return isNaN(v) ? props.modelValue : v.toLocaleString(i18n.locale.value);
                } else if (props.field.type === 'number' && !props.searchmode) {
                    // if field is a number, convert it to a float type to avoid issue with Prime InputNumber
                    let v = parseFloat(props.modelValue);
                    return isNaN(v) ? props.modelValue : v;
                } else if (['choices', 'fk'].includes(props.field.type) && !props.searchmode && !props.field.write){
                    return props.modelValue?.text ? props.modelValue.text : '-';
                } else if (props.field.type === 'choices') {
                    return props.modelValue?.id;
                } else {
                    return props.modelValue;
                }
            },
            set: function(value) {
                if (props.field.type === 'choices') {
                    emit('update:modelValue', {id:value, text:cmpn.value.props.options[value + 1]?.name});
                } else {
                    emit('update:modelValue', value);
                }
            }
        })

        const cmpn = ref();
        if (props.field.type === 'text' || (['choices', 'fk', 'number', 'datetime'].includes(props.field.type) && !props.searchmode && !props.field.write)) {
            // if field is in searchmode or readonly, display it as a readonly text input
            cmpn.value = h(InputText, {
                placeholder: props.field.title,
                disabled: !props.searchmode && !props.field.write,
                name:props.name,
            });
        }
        else if (props.field.type === 'number' && props.searchmode) {
            // if field is a number in search mode, display it as a RangeInput
            cmpn.value = h(RangeInteger, {
                name:props.name,
            });
        }
        else if (props.field.type === 'number') {
            // if field is a number, display it as normal InputNumber
            cmpn.value = h(InputNumber,
            {
                mode:"decimal",
                minFractionDigits:2,
                maxFractionDigits:2,
                locale:i18n.locale.value,
                name:props.name,
            }
            );
        }
        else if (props.field.type === 'choices') {
            // add a blank choice, to permit reset of this field
            let dpchoices = [{value: null, name:''}];
            // adapt choices for compatibility with Prime Dropdown

            for (let k in props.field.choices) {
                let kp = parseInt(k, 10);
                dpchoices.push({value:isNaN(kp) ? k : kp, name:props.field.choices[k]})
            }

            cmpn.value = h(Dropdown,{
                options: dpchoices,
                optionLabel: 'name',
                optionValue: 'value',
                placeholder: props.field.title,
                name:props.name,
            });
        }
        else if (props.field.type === 'fk') {
            cmpn.value = h(SchemaAutoComplete,{
                suggestionsUrl: props.field.autocomplete_url,
                placeholder: props.field.title,
                name:props.name,
            });
        }
        else if (props.field.type === 'datetime' && !props.searchmode) {
            // no searchmode, display Prime Calendar normaly
            cmpn.value = h(Calendar, {
                monthNavigator:true,
                yearNavigator:true,
                showTime:true,
                yearRange:"2000:2030",
                name:props.name,
            });
        }
        else if (props.field.type === 'datetime') {
            // searchmode : display a range date
            cmpn.value = h(RangeDate, {
                name:props.name,
            });
        }

        return {
            message,
            cmpn,
        }
    }
}
</script>
<style>
.p-datepicker table td > span {
    width: 1.5rem !important;
    height: 1.5rem !important;
    font-size: smaller;
}
.p-datepicker table td {
    padding: 0.2rem !important;
}
</style>
