<template>
    <div class="p-card p-p-3 stat-widget">
        <component :is=component></component>
    </div>
</template>

<script>
import { h } from "vue";
import StatTable from "@/components/stats/StatTable";
import StatText from "@/components/stats/StatText";
import StatChart from "@/components/stats/StatChart";

/*
*  @group Stats
*  Parent widget of stat widgets.
*/
export default {
    name: "StatWidget",
    props: {
        // Object from Schema that define this widget
        'widget': {
            // ```{
            //     "type": String "text" / "table" / "chart",
            //     "title": String,
            //     "description":  String,
            //     "container": String,
            //     "icon": String,
            //     "border": String,
            //     "function": String
            // }```
            type: Object,
            required: true,
        }
    },
    setup(props) {
        let componentClass = null;
        if (props.widget && props.widget.type === 'text') {
            componentClass = StatText;
        }
        else if (props.widget && props.widget.type === 'table') {
            componentClass = StatTable;
        }
        else if (props.widget && props.widget.type === 'chart') {
            componentClass = StatChart;
        }
        let component =  componentClass ? h(componentClass, props) : null;
        return {
            component
        }
    }
}
</script>

<style lang="scss">
.widget-title {
    font-size: 0.95rem;
    margin: 0 0 10px;
    font-weight: 500;
    color: #343a40;
}
.widget-blank {
    height: 284px;
    background-color: #e9ecef;
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
</style>
