import { createStore } from 'vuex';
import authModule from './modules/auth';
import schemaModule from './modules/schema';
import dataModule from './modules/data'

export default createStore({
  modules: {
      auth: authModule,
      schema: schemaModule,
      data: dataModule
  }
});
