<template>
    <div class="password-reset">
        <div class="container">
            <div class="p-d-flex p-jc-center">
                <div class="p-col p-md-8 p-lg-6 p-xl-5">
                    <Card class="p-mb-4">
                        <template #content class="p-p-4">
                            <div class="p-d-flex p-jc-center">
                                <img
                                    src="@/assets/theme/images/logo-login.png"
                                    class="logo-login p-m-6"
                                    alt="logo-login"
                                />
                            </div>
                            <h2 class="p-text-center" style="margin-bottom: 40px;">{{ $t('password_reset') }}</h2>
                            <form class="p-pl-5 p-pr-5 p-fluid" @submit.prevent="submitForm" v-if="!user_exist">
                                <Message v-for="msg of messages" :severity="msg.severity" :closable="false" :key="msg.content">{{msg.content}}</Message>
                                <div class="p-field p-mb-3">
                                    <label for="email">{{ $t("email_address") }}</label>
                                    <div class="p-inputgroup">
                                        <span class="p-inputgroup-addon">
                                          <i class="fal fa-at"></i>
                                        </span>
                                        <InputText
                                            autocomplete="off"
                                            id="email"
                                            type="text"
                                            required
                                            v-model="email"
                                        />
                                    </div>
                                </div>
                                <Button
                                    v-bind:label="$t('submit')"
                                    :disabled="!email"
                                    type="submit"
                                    class="p-mt-2"
                                />
                            </form>
                            <div v-else class="p-pl-5 p-pr-5">
                                <p class="p-text-justify">{{ $t('password_reset_done_msg_1') }}</p>
                                <p class="p-text-justify">{{ $t('password_reset_done_msg_2') }}</p>
                            </div>
                        </template>
                    </Card>
                    <div class="p-fluid p-text-center">
                        <router-link :to="{name: 'home'}" class="forgot-link">{{ $t("home") }}</router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, onMounted, onUnmounted } from "vue";
import { useStore } from "vuex"
import { useI18n } from "vue-i18n";

import Card from "primevue/card";
import InputText from "primevue/inputtext";
import Button from "primevue/button";
import Message from 'primevue/message';

export default {
    name: "PasswordResetView",
    components: {
        Card,
        InputText,
        Button,
        Message,
    },
    setup() {
        const i18n = useI18n();
        const store = useStore();

        const messages = ref([]);
        const email = ref(null);
        const user_exist = ref(false);

        function submitForm() {
            if (email.value) {
                messages.value = [];
                store.dispatch("auth/passwordReset", {
                    email: email.value,
                })
                    .then(
                        () => {
                            user_exist.value = true;
                        },
                        (error) => {
                            if (error.response) {
                                user_exist.value = true;
                            }
                            else {
                                messages.value.push({severity: 'error', content: i18n.t("error_occured_during_processing")});
                            }
                        }
                    );
            }
        }

        onMounted(function () {
            document.body.classList.add("authentication-bg");
        });

        onUnmounted(function () {
            document.body.classList.remove("authentication-bg");
        });

        return {
            email,
            submitForm,
            user_exist,
            messages
        };
    },
};
</script>

<style lang="css" scoped>
.password-reset p {
    line-height: 1.5;
    color: var(--text-color);
}
</style>
