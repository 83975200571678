<template>
    <div data-cy="list-view-table">
        <div class="table-responsive table-chart">
            <table class="table table-bordered table-centered table-striped">
                <thead>
                    <tr>
                        <th v-for="field_id in listDisplay" :key="field_id" :class="fields[field_id].align ? 'p-text-' + fields[field_id].align  : ''" @click='sortData(field_id)'>
                            <button class="sortable-btn">
                                {{ fields[field_id].title }}
                                <i v-if="order === field_id" class="fal fa-long-arrow-up p-ml-1"></i>
                                <i v-else-if="order === '-' + field_id" class="fal fa-long-arrow-down p-ml-1"></i>
                                <i v-else class="fal fa-arrows-v p-ml-1"></i>
                            </button>
                        </th>
                    </tr>
                </thead>
                <tbody v-if="dataList.length">
                    <tr v-for="rows in dataList" :key="rows">
                        <td v-for="field_id in listDisplay" :key="field_id"
                            @mouseleave="hideTooltip" @mouseenter="showTooltip(fieldDisplay(rows[field_id], field_id), $event)"
                            class="p-text-truncate" :class="fields[field_id].align ? 'p-text-' + fields[field_id].align  : ''">
                            <router-link v-if="fields[field_id].link" class="table-link" :to="{ 'name': fields[field_id].link, params: { 'id': rows[field_id] }}" >
                                {{ fieldDisplay(rows[field_id], field_id) }}
                            </router-link>
                            <span v-else>{{ fieldDisplay(rows[field_id], field_id) }}</span>
                        </td>
                    </tr>
                </tbody>
                <tbody v-else>
                    <tr>
                        <td :colspan="listDisplay.length" class="p-text-center">{{ $t('no_results') }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>


<script>
import {useStore} from "vuex";
import settings from "@/settings";
import {useI18n} from "vue-i18n";

/*
*  @group Widgets
*  Table is used to generate a table. Emits a `sort` event once a table head is clicked with value `fieldname` or `-fieldname`.
*/
export default {
    name: "Table",
    props: {
        // Define the current model name for this ModelForm
        "model": {
            type: String,
            required: true,
        },
        // List of values to display in this table
        'dataList': {
            type: Array,
            required: true,
        },
        // List of field names that this table has
        'listDisplay': {
            type: Array,
            required: true,
        },
        // used to init the table in a sorted state on specified field
        'order': {
            type: String,
            required: false,
            default: undefined
        },
    },
    emits: ['sort'],
    setup(props, context) {
        const i18n = useI18n();
        const store = useStore();
        const fields = store.getters['schema/getFields'](props.model);

        function fieldDisplay(value, field_id) {
            // used to transform data for display in table cell
            if (value === null || value === undefined || value === '') {
                return settings.EMPTY_DATA;
            }
            let type = fields[field_id].type;
            if (type === 'text') {
                return value;
            }
            else if (type === 'number') {
                let number = parseFloat(value);
                if (!number) {
                    return settings.EMPTY_DATA;
                }
                return number.toLocaleString(i18n.locale.value);
            }
            else if (type === 'choices') {
                let choice_value = value?.text;
                return choice_value ? choice_value : fields[field_id].title + ' ' + value;
            }
            else if (type === 'fk') {
                let fk_value = value?.text;
                return fk_value ? fk_value : fields[field_id].title + ' ' + value;
            }
            else if (type === 'datetime') {
                let date = new Date(value);
                if (!date) {
                    return settings.EMPTY_DATA;
                }
                return date.toLocaleDateString(i18n.locale.value)  + ' ' +
                    date.toLocaleTimeString(i18n.locale.value, {timeStyle: 'short'});
            }
            return value;
        }

        let tooltip = null;
        function showTooltip(text, event) {
            if (event.target.children.length > 0 && event.target.offsetWidth < event.target.scrollWidth) {
                let rect = event.target.getBoundingClientRect();
                tooltip = document.createElement('div');
                tooltip.className = 'tooltip';
                tooltip.innerText = text;
                tooltip.style.left = rect.left + 'px';
                tooltip.style.top = rect.top + document.documentElement.scrollTop + 'px';
                document.body.appendChild(tooltip);
            }
        }

        function hideTooltip() {
            if (tooltip) {
                tooltip.remove();
                tooltip = null;
            }
        }

        function sortData(field_id){
            // sort table rows based on selected field
            let order;
            // if current sort field is already field_id, sort in reverse
            if (props.order === field_id) {
                order = '-' + field_id;
            } else {
                order = field_id;
            }
            context.emit('sort', order);
        }

        return {
            fields,
            fieldDisplay,
            sortData,
            showTooltip,
            hideTooltip
        };
    }
}
</script>

<style lang="scss">
@import "src/assets/theme/scss/variable";
.tooltip {
    height: 42px;
    border: 1px solid #dee2e6;
    color: white;
    padding: 0.85rem;
    background-color: $primary-color;
    position: absolute;
    pointer-events: none;
}
.table-link {
    color: $secondary-color;
    font-weight: 500;
}
.table-link:hover {
    color: $primary-color;
    text-decoration: underline !important;
}
.sortable-btn {
    min-width: 100%;
    color: inherit;
    background: transparent;
    padding: 0;
    border: 0;
    text-align: inherit;
    font: inherit;
    text-transform: inherit;
    white-space: nowrap;
    overflow: hidden;
    cursor: pointer;
}
</style>
