<template>
    <component :is=component :data-cy="'schema-action-' + action"></component>
</template>

<script>

import { h } from "vue";
import Button from "primevue/button";
import Dropdown from 'primevue/dropdown';
import {useI18n} from "vue-i18n";
import {useStore} from "vuex";
/*
*  @group Widgets
*  SchemaAction is used to generate action button based on schema, intented to be used in `DetailView`
*/
export default {
    name: "SchemaAction",
    props: {
        // An object from schema containing actions properties
        'action': {
            type: String,
            required: true,
        },
        // Define the current model name for this Component
        "model": {
            type: String,
            required: true,
        },
    },
    emits: ['action'],
    setup(props, context) {
        const store = useStore();
        const i18n = useI18n();

        let component = null;
        if (props.action === 'export') {
            const exportFormats = store.getters["schema/getExportFormats"](props.model);
            component = h(Dropdown, {
                options : exportFormats,
                optionLabel:"text",
                class: 'p-float-right p-ml-2',
                placeholder: i18n.t(props.action),
                onChange: function ($event) {
                    context.emit('action', {
                        name: props.action,
                        value: $event.value.code
                    });
                }
            });
        }
        else {
            let button_class = null;
            if (props.action === 'save' || props.action === 'search') {
                button_class = 'p-button-primary p-float-right p-ml-2';
            }
            else if (props.action === 'delete') {
                button_class = 'p-button-danger p-float-left';
            }
            else if (props.action === 'reset') {
                button_class = 'p-button-secondary p-float-right';
            }
            component = h(Button, {
                label: i18n.t(props.action),
                class: button_class,
                onClick: function () {
                    context.emit('action', {
                        name: props.action
                    });
                }

            });
        }

        return {
            component,
        }
    }
}
</script>
