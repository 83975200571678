<template>
    <BaseContent>
        <div data-cy="dashboard">
            <h3 class="page-title p-mt-0">{{ $t('dashboard') }}</h3>
            <div class="p-grid" data-cy="dashboard-section" v-for="section in dashboard" :key="section">
                <div class="p-col-12 section-title p-mt-4" v-if="section.title">
                    <h4 data-cy="dashboard-section-title">{{ section.title }}</h4>
                    <hr>
                </div>
                <div v-for="widget in section.configuration" :key="widget" :class="widget.container">
                    <StatWidget v-if="!widget.is_shopping || (widget.is_shopping && isShopping)" data-cy="dashboard-widget" :widget="widget"></StatWidget>
                </div>
            </div>
        </div>
    </BaseContent>
</template>

<script>
import {useStore} from "vuex";
import BaseContent from "@/components/layouts/BaseContent";
import StatWidget from "@/components/stats/StatWidget";

export default {
    name: 'DashboardView',
    components: {
        BaseContent,
        StatWidget
    },
    setup() {
        const store = useStore();
        const dashboard = store.getters['schema/getDashboard'];
        const isShopping = store.getters['schema/isShopping'];
        return {
            dashboard,
            isShopping
        }
    }
}
</script>

<style lang="scss">
.section-title h4 {
    font-size: 1rem;
    margin: 0 0 7px;
    font-weight: 500;
    color: #343a40;
}
.section-title hr {
    border-color: #DCDDDF !important;
    border: 0;
    border-top: 1px solid #f7f7f7;
    box-sizing: content-box;
    height: 0;
    overflow: visible;
}
</style>
