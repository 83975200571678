
<template>
    <div v-if="data.value.deadlines" class="p-grid">
        <div class="p-col-12">
            <h4 class="header-title">{{ $t('cart_schedules') }}</h4>
            <div class="table-responsive">
                <table class="table table-bordered table-centered table-striped">
                    <thead>
                    <tr>
                        <th class="p-text-center">{{ $t('valid_from') }}</th>
                        <th class="p-text-center">{{ $t('valid_until') }}</th>
                        <th class="p-text-center">{{ $t('amount') }}</th>
                        <th class="p-text-center">{{ $t('status') }}</th>
                    </tr>
                    </thead>
                    <tbody>
                        <tr v-for="deadline in data.value.deadlines" :key="deadline">
                            <td class="p-text-center">{{ readableDate(deadline.not_before) }}</td>
                            <td class="p-text-center">{{ readableDate(deadline.not_after) }}</td>
                            <td class="p-text-center">{{ deadline.amount.toLocaleString() }}</td>
                            <td class="p-text-center">{{ deadline.status.text }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import { readableDate } from '@/mixins/filters';
/*
*  @group Embeded
*  An embeded that display Schedules for a Cart (intented to be used in DetailView)
*/
export default {
    name: 'CartSchedule',
    props: {
        // data of object currently displayed in the `DetailView`
        'data': {
            type: Object,
            required: true,
        },
        // list of errors to show
        'messages': {
            type: Object,
            required: true,
        }
    },
    setup() {
        return {
            readableDate
        }
    }
}
</script>
