<template>
    <div class="detail-view p-card" data-cy="detail-view">
        <div class="p-card-body">
            <div v-if="data && embededTop.length">
                <component v-for="embed in embededTop" :key="embed" :is="embed"></component>
            </div>
            <div v-else-if="!data && embededTop.length">
                <div style="height: 400px;"></div>
            </div>
            <Message v-for="msg of messages" :severity="msg.severity" :closable="true" :key="msg.content">{{msg.content}}</Message>
            <ModelForm :model="model" :searchmode="false" :fieldset="fieldset" :values="data" :actions="actions" @action="handleAction($event)" />
            <div v-if="data && embededBottom.length">
                <component v-for="embed in embededBottom" :key="embed" :is="embed"></component>
            </div>
        </div>
    </div>
</template>

<script>
import {useStore} from "vuex";
import { ref, onMounted, h } from "vue";
import ModelForm from "@/components/widgets/ModelForm";
import {useRouter} from "vue-router";
import {useI18n} from "vue-i18n";
import Message from 'primevue/message';
import { showError } from "@/mixins/message";

/*
*  @group Layouts
*  View used to view details of a model object
*/
export default {
    name: "DetailView",
    components: {
        ModelForm,
        Message
    },
    props: {
        // Define the current model name for this DetailView
        'model': {
            type: String,
            required: true,
        },
        // id of the model object to dislay in this DetailView
        'id': {
            type: [String, Number],
            required: true,
        },
        // embeded to prepend or append in this DetailView
        'embeded': {
            type: Array,
            required: false,
        },
    },
    setup(props) {
        const i18n = useI18n();
        const store = useStore();
        const data = ref(null);
        const initialData = ref({});
        const router = useRouter();
        const messages = ref([]);
        const fieldset = ref(store.getters["schema/getDetailFieldsets"](props.model));
        const fields = store.getters['schema/getFields'](props.model);
        const actions = ref(store.getters["schema/getDetailActions"](props.model));

        const embededTop = ref([]);
        const embededBottom = ref([]);

        // used to retrieve embeded from props, if any, and filter & order them
        function initEmbededView() {
            embededTop.value = [];
            embededBottom.value = [];
            if (props.embeded) {
                // embeded with negative weight goes to top, positive goes to bottom
                for (let e of props.embeded) {
                    let embedProps = {
                        data:data,
                        messages: messages
                    };
                    if (e.weight < 0) {
                        embededTop.value.push(
                            h(e.embed, embedProps)
                        );
                    }
                    if (e.weight > 0) {
                        embededBottom.value.push(
                            h(e.embed, embedProps)
                        );
                    }
                }
                // sort embeded descending
                embededTop.value.sort(function(a, b) {
                    if (a.weight < b.weight) return -1;
                    if (a.weight > b.weight) return 1;
                    return 0;
                });
                // sort embeded ascending
                embededBottom.value.sort(function(a, b) {
                    if (a.weight > b.weight) return -1;
                    if (a.weight < b.weight) return 1;
                    return 0;
                });
            }
        }

        function reloadSchema() {
            fieldset.value = store.getters["schema/getDetailFieldsets"](props.model);
            actions.value = store.getters["schema/getDetailActions"](props.model);
        }

        // function to get initial values of each fields
        function getData() {
            let payload = {
                id : props.id,
            }
            payload.modelUrl = store.getters["schema/getApiUrl"](props.model);
            store.dispatch('data/getModelDetails', payload).then(
                (response) => {
                    data.value = response.data;
                    initialData.value = {...response.data};
                },
                (error) => {
                    showError(error, messages, i18n);
                }
            );
        }

        // function to convert data before sending it to backend
        function prepareData() {
            let newData = {};
            for (let f in data.value) {
                let fieldType = fields[f]?.type;
                let fieldWrite = fields[f]?.write;
                if (fieldType && fieldWrite) {
                    switch (fieldType) {
                        case 'choices':
                            if (initialData.value[f]?.id !== data.value[f]?.id) {
                                newData[f] = data.value[f]?.id;
                            }
                            break;
                        case 'fk':
                            if (initialData.value[f]?.id !== data.value[f]?.id) {
                                newData[f] = data.value[f]?.id;
                            }
                            break;
                        default:
                            if (initialData.value[f] !== data.value[f]) {
                                newData[f] = data.value[f];
                            }
                            break;
                    }
                }
            }
            return newData;
        }

        function saveData() {
            if (data.value) {
                messages.value = [];
                let payload = {
                    data: prepareData(),
                };
                if (Object.keys(payload.data).length) {
                    payload.url = store.getters["schema/getApiUrl"](props.model) + props.id + '/';
                    store.dispatch('data/saveModelObject', payload).then(
                        (response) => {
                            data.value = response.data;
                            initialData.value = {...response.data};
                            reloadSchema();
                            messages.value.push({severity: 'success', content: i18n.t("changes_successfuly_saved")});
                        },
                        (error) => {
                            showError(error, messages, i18n);
                        }
                    );
                } else {
                    messages.value.push({severity: 'info', content: i18n.t("no_change_to_save")});
                }
            }
        }

        function deleteObject() {
            let payload = {};
            payload.url = store.getters["schema/getApiUrl"](props.model) + props.id + '/';
            store.dispatch('data/deleteModelObject', payload).then(
                () => {
                    router.push({ name: props.model + '-list'});
                },
                (error) => {
                    showError(error, messages, i18n);
                }
            );
        }

        function handleAction(event) {
            // handle action in event @action emitted by ModelForm, based on event.name
            switch (event.name) {
                case 'delete':
                    deleteObject();
                    break;
                case 'reset':
                    getData() // reload to reset to backend values
                    break;
                case 'save':
                    saveData();
                    break;
            }
        }

        onMounted(() => {
            getData();
            initEmbededView();
		})

        return {
            data,
            fieldset,
            actions,
            handleAction,
            embededTop,
            embededBottom,
            initialData,
            messages
        }
    }
}
</script>
