import settings from "@/settings";

export default {
    getFields(state) {
        return function (model) {
            if (state.schema[model] && state.schema[model].fields) {
                return state.schema[model].fields;
            }
            return null;
        };
    },
    getSearchFieldsets(state) {
        return function (model) {
            if (state.schema[model] && state.schema[model].search_fieldsets) {
                return state.schema[model].search_fieldsets;
            }
            return null;
        };
    },
    getListDisplay(state) {
        return function (model) {
            if (state.schema[model] && state.schema[model].list_display) {
                return state.schema[model].list_display;
            }
            return null;
        };
    },
    getDetailFieldsets(state) {
        return function (model) {
            if (state.schema[model] && state.schema[model].detail_fieldsets) {
                return state.schema[model].detail_fieldsets;
            }
            return null;
        };
    },
    getSearchActions(state) {
        return function (model) {
            if (state.schema[model] && state.schema[model].actions && state.schema[model].actions.search) {
                return state.schema[model].actions.search;
            }
            return null;
        };
    },
    getDetailActions(state) {
        return function (model) {
            if (state.schema[model] && state.schema[model].actions && state.schema[model].actions.detail) {
                return state.schema[model].actions.detail;
            }
            return null;
        };
    },
    getExportFormats(state) {
        return function (model) {
            if (state.schema[model] && state.schema[model].export_formats) {
                let formats = [];
                for (let i = 0; i < state.schema[model].export_formats.length; i++) {
                    let format = state.schema[model].export_formats[i];
                    formats.push({
                       'text': format.toUpperCase(),
                       'code': format
                    });
                }
                return formats;
            }
            return null;
        };
    },
    getDashboard(state) {
        return state.schema.dashboard;
    },
    isShopping(state) {
        if (state.schema && state.schema['app'] && state.schema['app'].is_shopping) {
            return state.schema['app'].is_shopping;
        }
        return false;
    },
    tokenLifetime(state) {
        if (state.schema && state.schema['app'] && state.schema['app'].token_lifetime) {
            return state.schema['app'].token_lifetime;
        }
        return settings.TOKEN_LIFETIME;
    },
    getPublicScripts(state) {
        if (state.schema && state.schema['app'] && state.schema['app'].public_scripts) {
            return state.schema['app'].public_scripts;
        }
        return [];
    },
    getExportUrl(state) {
        return function (model) {
            if (state.schema[model] && state.schema[model].api_export) {
                return settings.BASE_URL + state.schema[model].api_export;
            }
            return null;
        };
    },
    getApiUrl(state) {
        return function (model) {
            if (state.schema[model] && state.schema[model].api_view) {
                return settings.BASE_URL + state.schema[model].api_view;
            }
            return null;
        };
    }
};
