import platform from "./platform";

const CASHBACK_URL = platform.CASHBACK_URL ? platform.CASHBACK_URL: window.location.origin;

const settings =  {
    LOGIN_THROTTLE : 1, //seconds
    TOKEN_LIFETIME: 10, //minutes
    GG_API_KEY: 'AIzaSyCxnwwodHLne5PdeJpCwghZF2psRztcjjc',
    BASE_URL : CASHBACK_URL,
    API_URL : CASHBACK_URL + '/api/1',
    LOGIN_URL: CASHBACK_URL + '/api/auth/login/',
    PASSWORD_RESET_URL: CASHBACK_URL + '/cashboard/password/reset/',
    PASSWORD_RESET_CONFIRM_URL: CASHBACK_URL + '/cashboard/password/reset/confirm/',
    ADMIN_URL: CASHBACK_URL + '/admin/',
    SCHEMA_URL: CASHBACK_URL +  '/cashboard/schema/',
    STATISTICS_URL: CASHBACK_URL +  '/cashboard/statistics/',
    MAP_PAGE_SIZE: 100,
    LIST_PAGE_SIZE: 100,
    EMPTY_DATA: '-',
    PLATFORM: platform
}

export default settings
