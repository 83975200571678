import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import PrimeVue from 'primevue/config';

import 'animate.css'
import 'primevue/resources/primevue.min.css';
import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css';
import 'primevue/resources/themes/saga-blue/theme.css';

import './assets/base/icons/css/light.css'
import './assets/base/icons/css/fontawesome.css'
import './assets/base/scss/base.scss'
import './assets/base/scss/font.scss'
import './assets/base/scss/responsive.scss'

import { createI18n } from "vue-i18n";
import en_translations from "@/locales/en";
import {fr_translations, prime_fr_translations} from "@/locales/fr";

String.prototype.toTitleCase = function () {
    return this.replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();});
};

Array.prototype.clear = function() {
    this.splice(0, this.length);
};


let language = navigator.language.indexOf('-') !== -1 ? navigator.language.substr(0, navigator.language.indexOf('-')) : navigator.language;
if (language !== 'fr' && language !== 'en') {
    language = 'en';
}
let translations = {
    en: en_translations,
    fr: fr_translations
}
const i18n = createI18n({
    locale: language,
    fallbackLocale: 'en',
    messages: translations,
});
document.documentElement.lang = language;


const app = createApp(App).use(store).use(router);
let primeOptions = {
    ripple: true
}
if (language === 'fr') {
    primeOptions['locale'] = prime_fr_translations
}
app.use(PrimeVue, primeOptions);
app.use(i18n);
app.mount('#app');
