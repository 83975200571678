const en_translations = {
    dashboard: 'Dashboard',
    transaction: 'Transaction',
    transactions: 'Transactions',
    cart: 'Cart',
    carts: 'Carts',
    pos: 'Point of sale',
    Pos: 'Points of sale',
    pointofsale: 'Point of sale',
    map: 'Map',
    password_reset: 'Reset password',
    administration: 'Administration',
    settings: 'Settings',
    username: 'Username',
    password: 'Password',
    sign_in: 'Sign in',
    signing_in : 'Signing in',
    forgot_password: 'Forgot password',
    page_not_found: 'Page not found',
    page_not_found_text: 'We\'re sorry, but the page you requested does not exist. If you believe this is an error, please contact your administrator.',
    email_address: 'Email address',
    home: 'Home',
    submit: 'Submit',
    password_reset_done_msg_1: 'We\'ve emailed you instructions for setting your password, if an account exists with the email you entered. You should receive them shortly.',
    password_reset_done_msg_2: 'If you don\'t receive an email, please make sure you\'ve entered the address you registered with, and check your spam folder.',
    maintenance: 'Maintenance',
    site_unavailable: 'Site unavailable',
    site_unavailable_text: 'The site is temporarily unavailable due to maintenance activities. You can try to sign in again in a few minutes.',
    thanks_understanding: 'Thank you for your understanding.',
    no_opening_hours: 'Opening hours not available',
    changes_successfuly_saved: "Changes successfuly saved",
    no_change_to_save: "No change to save.",
    user_identity_successfuly_reset: "User identity successfuly reset",
    cart_successfuly_canceled: "Cart successfuly canceled",

    unable_to_login: 'Unable to login',
    error_occured_during_processing :"An error occured while processing your request",
    unable_to_connect_to_server : "Unable to connect to server",
    unknown_error_occured_while_connecting : "An unknown error occured while trying to connect to server",
    unknown_error : "Unknown error",
    error_occured_while_loading_app : "An error occured while loading the application.",
    error_occured_while_loading_pos : "An error occured while loading the points of sale.",
    no_data_available: 'No data available',
    new_password: 'New password',
    new_password_confirm: 'Confirm new password',
    update_password: 'Update password',
    password_validation_1: 'Your password cannot be too similar to your other personal information.',
    password_validation_2: 'Your password cannot be a commonly used password.',
    password_validation_3: 'A password must contain at least 1 uppercase, 1 lowercase, 1 digit, 1 special character and has a length of at least 12 characters.',
    password_reset_complete_msg: 'Your password has been set. You may go ahead and sign in now.',

    search: "Search",
    export: "Export",
    reset: 'Reset',
    save: 'Save',
    delete: 'Delete',

    from:'From',
    to: 'To',
    min: 'Min',
    max: 'Max',

    no_results : 'No results',
    showing_x_lines_of_x: 'Showing {0} lines of {1}',
    deposit: 'Deposit',
    search_address: 'Search an address',
    search_pointcash: 'Search an address',
    day: "Day",
    monday: "Monday",
    tuesday: "Tuesday",
    wednesday: "Wednesday",
    thursday: "Thursday",
    friday: "Friday",
    saturday: "Saturday",
    sunday: "Sunday",
    schedules: "Schedules",
    schedules_information_only: "Schedules are for information only",
    best_navigation: "Click below for the fastest route to the selected point of sale.",
    route: 'Route',
    map_loading: 'Loading points of sale ...',
    no_geoloc: 'In order to find the closest stores to your location, please enter an address in the address bar above.',

    remove_route: 'Remove route',

    // CartSchedule.vue
    cart_schedules : "Cart schedules",
    valid_from: "Valid from",
    valid_until: "Valid until",
    amount: "Amount",
    status: "Status",

    //CartTransaction.vue
    look_for_corresponding_transactions: "Look for corresponding transactions",
    cancel_this_cart: "Cancel this cart",

    //KycUserDelete.vue
    reset_user_identity: "Reset user identity",

    //OpeningHours.vue
    schedules_are_for_information_only : "Schedules are for information only",
    opening_hours : "Opening hours",
    week_day : "Week day",

    http : {
        bad_request : "Invalid data",
        unauthorized : "An authentication is requiered for this action",
        forbidden : "Your account does not have sufficient writes for this action",
        internal_server_error : "An error occured while working on your request",
    }
}

export default en_translations;
