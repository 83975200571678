<template>
    <div class="p-grid p-nogutter"  data-cy="dashboard-widget-text">
        <div class="p-col-4 p-text-center">
            <div class="stat-number-icon-box rounded-circle border" :class="widget.border">
                <i class="font-22 stat-number-icon-title" :class="widget.icon"></i>
            </div>
        </div>
        <div class="p-col-8">
            <div class="p-text-right">
                <Skeleton height="26px" class="p-mb-3 p-mt-2 p-overflow-hidden" v-if="!loaded" />
                <h3 class="p-mb-3 p-mt-2 stat-number-data" v-else-if="loaded && data">{{ data }}</h3>
                <h3 class="p-mb-3 p-mt-2 stat-number-no-data" v-else>{{ $t('no_data_available') }}</h3>
                <p class="stat-number-title p-text-truncate">{{ widget.title }}</p>
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from 'vue'
import Skeleton from 'primevue/skeleton';
import { useStore } from "vuex";

/*
*  @group Stats
*  Text widget used to display Statistics as text
*/
export default {
    name: "StatText",
    props: {
        // Object from Schema that define this widget
        'widget': {
            // See `StatWidget`
            type: Object,
            required: true,
        }
    },
    components: {
        Skeleton
    },
    setup(props) {
        const store = useStore();
        const data = ref(null);
        const loaded = ref(false);
        if (props.widget && props.widget.function) {
            store.dispatch('data/getStatistics', props.widget.function).then(
                (response) => {
                    loaded.value = true;
                    data.value = response.data;
                },
                (error) => {
                    loaded.value = true;
                    console.log(error);
                }
            );
        }
        return {
            data,
            loaded
        }
    }
}
</script>

<style lang="scss" scoped>
.stat-number-title {
    color: #98a6ad;
    font-size: .875rem;
    margin: 0;
}
.stat-number-icon-title {
    -ms-flex-align: center;
    align-items: center;
    color: #fff;
    display: -ms-flexbox;
    display: flex;
    height: 100%;
    -ms-flex-pack: center;
    justify-content: center;
    width: 100%;
    font-size: 22px;
}
.stat-number-data {
    font-size: 24px;
    color: #323a46;
    font-weight: 500;
}
.stat-number-no-data {
    font-size: 15px;
    color: #323a46;
    font-weight: 500;
}
.stat-number-icon-box {
    height: 4.5rem;
    width: 4.5rem;
}
</style>
