import axios from "axios";
import settings from "@/settings";

export default {
    get(context) {
        const token = context.rootState.auth.token;
        const config = {
            method: 'get',
            url: settings.SCHEMA_URL,
            headers: { Authorization: 'Token ' + token}
        }
        return axios(config).then(response => {
            localStorage.setItem('schema', JSON.stringify(response.data));
            context.commit('set', response.data);
        });
    },
    clean(context) {
        localStorage.removeItem('schema');
        context.commit('set', null);
    },
    load(context) {
        const schema = localStorage.getItem('schema');
        if (schema) {
            try {
                context.commit('set', JSON.parse(schema));
                return true;
            }
            catch (error) {
                console.log(error);
            }
        }
        return false;
    },
};
