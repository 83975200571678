<template>
    <div data-cy="dashboard-widget-chart">
        <div class="widget-title p-mb-3" v-if="widget.title">{{ widget.title }}</div>
        <Chart v-if="data && widget.chart_type" :type="widget.chart_type" :data="data" :options="options" />
        <div class="widget-blank" v-else-if="loaded">
            <p class="p-align-center">{{ $t('no_data_available') }}</p>
        </div>
        <Skeleton height="284px" v-else />
    </div>
</template>

<script>
import Skeleton from 'primevue/skeleton';
import Chart from 'primevue/chart';
import { ref } from "vue";
import {useStore} from "vuex";

/*
*  @group Stats
*  Text widget used to display Statistics as Chart
*/
export default {
    name: "StatChart",
    props: {
        // Object from Schema that define this widget
        'widget': {
            // See `StatWidget`
            type: Object,
            required: true,
        }
    },
    components: {
        Skeleton,
        Chart
    },
    setup(props) {
        const store = useStore();
        const data = ref(null);
        const loaded = ref(false);

        const options = {
            responsive: true,
            scales: props.widget.stacked ? {
                xAxes: [{
                    stacked: true,
                }],
                yAxes: [{
                    stacked: true
                }]
            } : false,
            tooltips: {
                mode: 'index',
                intersect: true
            },
            hover: {
                animationDuration: 0
            },
            legend: {
                display: props.widget.legend ? props.widget.legend : false,
                onClick: null
            },
            animation: {
                animateScale: true,
                animateRotate: true,
                onComplete: function() {
                    let chartInstance = this.chart;
                    let ctx = chartInstance.ctx;
                    this.data.datasets[0].data.forEach(function(data, index) {
                        if (props.widget.stacked) {
                            let total = 0;
                            let datasets_length = this.data.datasets.length;
                            for (let i = 0; i < datasets_length; i++) {
                                total += this.data.datasets[i].data[index];
                            }
                            if (total) {
                                total = total.toFixed(1);
                                var meta = chartInstance.controller.getDatasetMeta(datasets_length - 1);
                                var posX = meta.data[index]._model.x;
                                var posY = meta.data[index]._model.y;
                                ctx.fillStyle = "grey";
                                let decalX = total.length * 3;
                                ctx.fillText(total, posX - decalX, posY - 10);
                            }
                        }
                    }, this);
                }
            }
        }

        if (props.widget && props.widget.function) {
            store.dispatch('data/getStatistics', props.widget.function).then(
                (response) => {
                    loaded.value = true;
                    data.value = response.data;
                },
                (error) => {
                    loaded.value = true;
                    console.log(error);
                }
            );
        }
        return {
            data,
            loaded,
            options
        }
    }
}
</script>

<style lang="scss" scoped>

</style>
