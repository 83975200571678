export default {
    token(state) {
        return state.token;
    },
    tokenExpiration(state) {
        return state.tokenExpiration;
    },
    isAuthenticated(state) {
        const expiresIn = +state.tokenExpiration - new Date().getTime();
        return !!(state.token && expiresIn > 0);
    },
};
