<template>
  <div class="login">
    <div class="container">
      <div class="p-d-flex p-jc-center">
        <div class="p-col p-md-8 p-lg-6 p-xl-5">
          <Card class="p-mb-4">
            <template #content class="p-p-4">
              <div class="p-d-flex p-jc-center">
                <img
                  src="@/assets/theme/images/logo-login.png"
                  class="logo-login p-m-6"
                  alt="logo-login"
                />
              </div>
              <form class="p-pl-5 p-pr-5 p-fluid" @submit.prevent="submitForm">
                <Message v-for="msg of messages" :severity="msg.severity" :closable="false" :key="msg.content">{{msg.content}}</Message>
                <div class="p-field p-mb-3">
                  <label for="username">{{ $t("username") }}</label>
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="fal fa-user"></i>
                    </span>
                    <InputText
                      autocomplete="off"
                      id="username"
                      type="text"
                      required
                      v-model="username"
                    />
                  </div>
                </div>
                <div class="p-field p-mb-2">
                  <label for="password">{{ $t("password") }}</label>
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="fal fa-lock"></i>
                    </span>
                    <InputText
                      autocomplete="off"
                      id="password"
                      type="password"
                      required
                      v-model="password"
                    />
                  </div>
                </div>
                <Button
                  v-bind:label="!loading ? $t('sign_in') : $t('signing_in')"
                  :disabled="loading || !username || !password"
                  type="submit"
                  class="p-mt-2"
                />
              </form>
            </template>
          </Card>
          <div class="p-fluid p-text-center">
            <router-link :to="{name: 'password-reset'}" class="forgot-link">{{ $t("forgot_password") }} ?</router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, onUnmounted } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex"
import { useI18n } from "vue-i18n";

import Card from "primevue/card";
import InputText from "primevue/inputtext";
import Button from "primevue/button";
import Message from 'primevue/message';

import settings from "@/settings";

export default {
    name: "LoginView",
    components: {
        Card,
        InputText,
        Button,
        Message
    },
    setup() {
        const i18n = useI18n();
        const router = useRouter();
        const store = useStore();

        const messages = ref([]);
        const username = ref(null);
        const password = ref(null);
        const loading = ref(false);
        let attempts = 0;

        function submitForm() {
            loading.value = true;
            setTimeout(function () {
                if (username.value && password.value) {
                    messages.value = [];
                    store.dispatch("auth/login", {
                        username: username.value,
                        password: password.value,
                    })
                        .then(
                            () => {
                                store.dispatch("schema/get").then(
                                    () => {
                                        loading.value = false;
                                        if (router.currentRoute.value.query.next) {
                                            router.replace(router.currentRoute.value.query.next);
                                        }
                                        else {
                                            router.replace({name: "home"});
                                        }
                                    },
                                    (error) => {
                                        loading.value = false;
                                        let msg = error.response && error.response.data && error.response.data.message ?
                                            error.response.data.message : i18n.t("error_occured_while_loading_app");
                                        messages.value.push({severity: 'error', content: msg});
                                        store.dispatch('auth/logout');
                                    }
                                );
                            },
                            (error) => {
                                let msg = error.response && error.response.data && error.response.data.detail ?
                                    error.response.data.detail : i18n.t("unknown_error_occured_while_connecting");
                                messages.value.push({severity: 'error', content: msg});
                                loading.value = false;
                                attempts += 1;
                            }
                        );
                }
            }, (attempts ? settings.LOGIN_THROTTLE * 1000 : 0));
        }

        onMounted(function () {
            document.body.classList.add("authentication-bg");
        });

        onUnmounted(function () {
            document.body.classList.remove("authentication-bg");
        });

        return {
            username,
            password,
            submitForm,
            loading,
            messages
        };
    },
};
</script>
