<template>
    <BaseContent>
        <h3 class="page-title p-mt-0">{{ $t('carts') }}</h3>
        <ListView :model="model"></ListView>
    </BaseContent>
</template>

<script>
import BaseContent from "@/components/layouts/BaseContent";
import ListView from "@/components/layouts/ListView";
/* @vuese
*  @group ListView
*  List view for `Cart`
*/
export default {
    name: 'CartListView',
    components: {
        ListView,
        BaseContent
    },
    setup() {
        return {
            model: 'cart'
        }
    }
}
</script>

<style lang="scss" scoped>

</style>
