<template>
    <div class="pos-map">
        <div id="map" data-nosnippet v-if="data.value.latitude && data.value.longitude" class="p-mb-4"></div>
        <div class="map-infowindow" v-show="false">
            <div ref="infowindow_element">
                <h4 class="p-mb-3 p-mt-0">{{ data.value.storename }}</h4>
                <div class="p-mb-2">{{ data.value.address }}</div>
                <div><span>{{ data.value.zipcode }}</span> <span>{{ data.value.city }}</span></div>
            </div>
        </div>
    </div>
</template>

<script>
import {onMounted, ref} from 'vue';
import { Loader } from 'google-maps';
import settings from '@/settings'

/*
*  @group Embeded
*  An embeded that display a map with location for a point of sale (intented to be used in DetailView)
*/
export default {
    name: 'DetailMap',
    props: {
        // data of object currently displayed in the `DetailView`
        'data': {
            type: Object,
            required: true,
        },
        // list of errors to show
        'messages': {
            type: Object,
            required: true,
        }
    },
    setup(props) {

        let map = null;
        const infowindow_element = ref(null)
        const marker_icon = require('@/assets/theme/images/marker.png');

        function initMap() {
            if (props.data && props.data.value && props.data.value.latitude && props.data.value.longitude) {
                let position = {
                    lat: props.data.value.latitude,
                    lng: props.data.value.longitude
                }
                const options = {
                    center: position,
                    zoomControlOptions: { position: window.google.maps.ControlPosition.BOTTOM_LEFT },
                    mapTypeControl: false,
                    fullscreenControl: false,
                    streetViewControl: false,
                    zoom: 14
                }
                map = new window.google.maps.Map(document.getElementById('map'), options);
                let infowindow = new window.google.maps.InfoWindow({
                    content: infowindow_element.value,
                });
                let marker = new window.google.maps.Marker({
                    position: position,
                    icon: marker_icon,
                    map: map
                });
                infowindow.open(map, marker);
            }
        }

        onMounted(function() {
            if (!window.google) {
                let api_key = settings.PLATFORM.GG_API_KEY ? settings.PLATFORM.GG_API_KEY : settings.GG_API_KEY;
                const loader = new Loader(api_key, {
                    libraries: ['places'],
                });
                loader.load().then(function (google) {
                    window.google = google;
                    initMap();
                });
            }
            else {
                initMap();
            }
        });

        return {
            infowindow_element
        }
    }
}
</script>

<style lang="scss" scoped>
#map {
    width: 100%;
    height: 400px;
}
</style>

