import axios from "axios";
import settings from "@/settings";

export default {
    login(context, payload) {
        return axios.post(settings.LOGIN_URL, payload)
            .then(response => {
                localStorage.setItem('token', response.data.token);
                context.commit('setToken', response.data.token);
            });
    },
    logout(context) {
        localStorage.removeItem('token');
        localStorage.removeItem('tokenExpiration');
        context.commit('setToken', null);
        context.commit('setTokenExpiration', null);
        context.dispatch('schema/clean', null, {root:true})
    },
    passwordReset(context, payload) {
        return axios.post(settings.PASSWORD_RESET_URL, payload);
    },
    passwordResetConfirm(context, payload) {
        return axios.post(settings.PASSWORD_RESET_CONFIRM_URL, payload);
    },
    load(context) {
        const tokenExpiration = localStorage.getItem('tokenExpiration');
        const expiresIn = +tokenExpiration - new Date().getTime();
        if (expiresIn > 0) {
            const token = localStorage.getItem('token');
            if (token) {
                context.commit('setToken', token);
                context.commit('setTokenExpiration', tokenExpiration);
                return true;
            }
        }
        return false;
    }
};
