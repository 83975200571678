<template>
    <div data-cy="dashboard-widget-table">
        <div class="widget-title p-mb-3" v-if="widget.title">{{ widget.title }}</div>

        <div class="table-responsive table-chart" v-if="!loaded">
            <table class="table table-bordered table-centered table-striped">
                <thead>
                    <tr>
                        <th><Skeleton height="1rem" /></th>
                        <th><Skeleton height="1rem" /></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="index in 5" :key="index">
                        <th><Skeleton height="1rem" /></th>
                        <th><Skeleton height="1rem" /></th>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="table-responsive table-chart" v-else-if="loaded && data && data.header && data.data">
            <table class="table table-bordered table-centered table-striped">
                <thead>
                    <tr>
                        <th v-for="head in data.header" :key="head.title" :class="{'p-text-center': head.align }">
                            {{ head.title }}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="dataset in data.data" :key="dataset">
                        <th v-for="(head, index) in data.header" :key="head.title" :class="{'p-text-center': head.align }">
                            {{ dataset[index] }}
                        </th>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="widget-blank" v-else>
            <p class="p-align-center">{{ $t('no_data_available') }}</p>
        </div>

    </div>
</template>


<script>
import Skeleton from 'primevue/skeleton';
import {ref} from "vue";
import {useStore} from "vuex";

/*
*  @group Stats
*  Text widget used to display Statistics as table
*/
export default {
    name: "StatTable",
    props: {
        // Object from Schema that define this widget
        'widget': {
            // See `StatWidget`
            type: Object,
            required: true,
        }
    },
    components: {
        Skeleton
    },
    setup(props) {
        const store = useStore();
        const data = ref(null);
        const loaded = ref(false);
        if (props.widget && props.widget.function) {
            store.dispatch('data/getStatistics', props.widget.function).then(
                (response) => {
                    loaded.value = true;
                    data.value = response.data;
                },
                (error) => {
                    loaded.value = true;
                    console.log(error);
                }
            );
        }
        return {
            data,
            loaded
        }
    }
}
</script>

<style lang="scss" scoped>

</style>
