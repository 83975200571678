<template>
    <BaseContent>
        <h3 class="page-title p-mt-0">{{ $t('Pos') }}</h3>
        <ListView :model="model"></ListView>
    </BaseContent>
</template>

<script>
import BaseContent from "@/components/layouts/BaseContent";
import ListView from "@/components/layouts/ListView";
/* @vuese
*  @group ListView
*  List view for `Pos` (Point of Sale)
*/
export default {
    name: 'PosListView',
    components: {
        BaseContent,
        ListView
    },
    setup() {
        return {
            model: 'pointofsale'
        }
    }
}
</script>

<style lang="scss" scoped>

</style>
