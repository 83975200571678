
<template>
    <div class="p-grid">
        <div class="p-col p-xl-6">
            <h4 class="header-title">{{ $t('opening_hours') }}</h4>
            <div class="table-responsive p-mb-2">
                <table class="table table-bordered table-centered table-striped">
                    <thead>
                    <tr>
                        <th>{{ $t('week_day') }} </th>
                        <th class="p-text-center">{{ $t('schedules') }}</th>
                    </tr>
                    </thead>
                    <tbody v-if="Object.keys(data.value.openinghours).length">
                        <tr v-for="(hours, day) in data.value.openinghours" :key="day">
                            <td>{{ $t(day) }}</td>
                            <td class="p-text-center">
                                <span>{{ hours.join(', ')}}</span>
                            </td>
                        </tr>
                    </tbody>
                    <tbody v-else>
                        <tr>
                            <td colspan="2" class="p-text-center">{{ $t('no_opening_hours') }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <small><i>{{ $t('schedules_are_for_information_only') }}</i></small>
        </div>
    </div>
</template>

<script>
/*
*  @group Embeded
*  An embeded that display Opening Hours (échéancier de paiment) for a Cart (intented to be used in DetailView)
*/
export default {
    name: 'OpeningHours',
    props: {
        // data of object currently displayed in the `DetailView`
        'data': {
            type: Object,
            required: true,
        },
        // list of errors to show
        'messages': {
            type: Object,
            required: true,
        }
    },
}
</script>
