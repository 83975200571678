export default {
    setUser(state, user) {
        state.user = user;
    },
    setToken(state, token) {
        state.token = token;
    },
    setTokenExpiration(state, date) {
        state.tokenExpiration = date;
    },
};
