<template>
    <BaseContent>
        <h3 class="page-title p-mt-0">{{ $t('transaction') }}: #{{ id }}</h3>
        <DetailView :model="model" :id="id"></DetailView>
    </BaseContent>
</template>

<script>
import BaseContent from "@/components/layouts/BaseContent";
import DetailView from "@/components/layouts/DetailView";

/* @vuese
*  @group DetailView
*  Detail view for `Transactions`
*/
export default {
    name: 'TransactionDetailView',
    components: {
        BaseContent,
        DetailView
    },
    props: {
        // id of the object to display
        'id': {
            type: [String, Number],
            required: true,
        },
    },
    setup() {
        return {
            model: 'transaction',
        }
    }
}
</script>
