import {useI18n} from "vue-i18n";

function readableDate(value) {
    const i18n = useI18n();
    let d = new Date(value);
    return d.toLocaleDateString(i18n.locale.value, {month:"2-digit",day:"2-digit", year:"numeric"}) + ' ' + d.toLocaleTimeString(i18n.locale.value, {timeStyle: 'short'})
}

export {
    readableDate
};
