<template>
    <BaseContent>
        <h3 class="page-title p-mt-0">{{ $t('map') }}</h3>
        <Map class="p-card animate__animated animate__fadeIn"></Map>
    </BaseContent>
</template>

<script>
import BaseContent from "@/components/layouts/BaseContent";
import Map from '@/components/widgets/Map'

export default {
    name: 'MapView',
    components: {
        BaseContent,
        Map
    }
}
</script>
