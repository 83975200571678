import axios from "axios";
import settings from "@/settings";

export default {
    getData(context, payload) {
        const token = context.rootState.auth.token;
        if (!payload.url) {
            console.error('getData missing url param');
        }
        const config = {
            method: 'get',
            url: payload.url,
        }
        if (payload.params) {
            config['params'] = payload.params;
        }
        config['headers'] = { Authorization: 'Token ' + token};
        return axios(config);
    },
    getStatistics(context, func) {
        const token = context.rootState.auth.token;
        const config = {
            method: 'get',
            url: settings.STATISTICS_URL + func + '/',
            headers: { Authorization: 'Token ' + token}
        }
        return axios(config);
    },
    getModelList(context, payload) {
        const token = context.rootState.auth.token;
        let params = {};
        let modelUrl = payload.modelUrl;
        if ('page_size' in payload) {
            params.page_size = payload.page_size;
        } else {
            params.page_size = settings.LIST_PAGE_SIZE;
        }
        if ('page' in payload) {
            params.page = payload.page;
        }
        if ('order' in payload) {
            params['order_by'] = payload.order;
        }
        if ('filter' in payload) {
            for (let k in payload.filter) {
                if (!(k in params)) {
                    params[k] = payload.filter[k];
                }
            }
        }
        const config = {
            method: 'get',
            url: modelUrl,
            headers: { Authorization: 'Token ' + token},
            params: params,
        }
        return axios(config);
    },
    getModelDetails(context, payload) {
        const token = context.rootState.auth.token;
        let params = {};
        let id;
        let modelUrl = payload.modelUrl;
        if ('id' in payload) {
            id = payload.id;
        }
        const config = {
            method: 'get',
            url: modelUrl + id + '/',
            headers: { Authorization: 'Token ' + token},
            params: params,
        }
        return axios(config);
    },

    saveModelObject(context, payload) {
        const token = context.rootState.auth.token;
        if (!payload.url) {
            console.error('saveModelObject missing url param');
        }
        const config = {
            method: 'patch',
            url: payload.url,
        }
        if (payload.data) {
            config['data'] = payload.data;
        }
        config['headers'] = { Authorization: 'Token ' + token};

        return axios(config);
    },
    deleteModelObject(context, payload) {
        const token = context.rootState.auth.token;
        if (!payload.url) {
            console.error('deleteModelObject missing url param');
        }
        const config = {
            method: 'delete',
            url: payload.url,
        }
        config['headers'] = { Authorization: 'Token ' + token};
        return axios(config);
    },

    getModelExport(context, payload) {
        const token = context.rootState.auth.token;
        let params = {};
        // let model = payload.model;
        let url = payload.url;
        if ('format' in payload) {
            params.format = payload.format;
        }
        if ('order' in payload) {
            params['order_by'] = payload.order;
        }
        if ('filter' in payload) {
            for (let k in payload.filter) {
                if (!(k in params)) {
                    params[k] = payload.filter[k];
                }
            }
        }
        const config = {
            method: 'get',
            url: url,
            headers: { Authorization: 'Token ' + token},
            params: params,
        }
        return axios(config);
    },
    getSuggestions(context, payload) {
        const token = context.rootState.auth.token;
        let url = payload.url
        const config = {
            method: 'get',
            url: settings.BASE_URL + url,
            headers: { Authorization: 'Token ' + token},
        }
        if (payload.query) {
            config.params = {'q':payload.query};
        }
        return axios(config);
    }


};
