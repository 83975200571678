<template>
    <div class="password-reset">
        <div class="container">
            <div class="p-d-flex p-jc-center">
                <div class="p-col p-md-8 p-lg-6 p-xl-5">
                    <Card class="p-mb-4">
                        <template #content class="p-p-4">
                            <div class="p-d-flex p-jc-center">
                                <img
                                    src="@/assets/theme/images/logo-login.png"
                                    class="logo-login p-m-6"
                                    alt="logo-login"
                                />
                            </div>
                            <h2 class="p-text-center" style="margin-bottom: 40px;">{{ $t('password_reset') }}</h2>
                            <form class="p-pl-5 p-pr-5 p-fluid" @submit.prevent="submitForm" v-if="!password_changed">
                                <Message v-for="msg of messages" :severity="msg.severity" :closable="false" :key="msg.content">{{msg.content}}</Message>
                                <div class="p-field p-mb-3">
                                    <label for="new_password">{{ $t("new_password") }}</label>
                                    <div class="p-inputgroup">
                                        <span class="p-inputgroup-addon">
                                          <i class="fal fa-lock"></i>
                                        </span>
                                        <InputText
                                            autocomplete="off"
                                            id="new_password"
                                            type="password"
                                            required
                                            v-model="new_password"
                                        />
                                    </div>
                                </div>
                                <small id="hint_id_new_password1" class="form-text text-muted">
                                    <ul>
                                        <li>{{ $t('password_validation_1') }}</li>
                                        <li>{{ $t('password_validation_2') }}</li>
                                        <li>{{ $t('password_validation_3') }}</li>
                                    </ul>
                                </small>
                                <div class="p-field p-mb-3">
                                    <label for="new_password_confirm">{{ $t("new_password_confirm") }}</label>
                                    <div class="p-inputgroup">
                                        <span class="p-inputgroup-addon">
                                          <i class="fal fa-lock"></i>
                                        </span>
                                        <InputText
                                            autocomplete="off"
                                            id="new_password_confirm"
                                            type="password"
                                            required
                                            v-model="new_password_confirm"
                                        />
                                    </div>
                                </div>
                                <Button
                                    v-bind:label="$t('update_password')"
                                    :disabled="!new_password || !new_password_confirm"
                                    type="submit"
                                    class="p-mt-2"
                                />
                            </form>
                            <div v-else class="p-pl-5 p-pr-5">
                                <p class="p-text-justify">{{ $t('password_reset_complete_msg') }}</p>
                            </div>
                        </template>
                    </Card>
                    <div class="p-fluid p-text-center">
                        <router-link :to="{name: 'home'}" class="forgot-link">{{ $t("home") }}</router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, onMounted, onUnmounted } from "vue";
import { useStore } from "vuex"
import { useI18n } from "vue-i18n";

import Card from "primevue/card";
import InputText from "primevue/inputtext";
import Button from "primevue/button";
import Message from 'primevue/message';

import {useRouter} from "vue-router";

export default {
    name: "PasswordResetConfirmView",
    props: ['uid', 'token'],
    components: {
        Card,
        InputText,
        Button,
        Message,
    },
    setup(props) {
        const i18n = useI18n();
        const router = useRouter();
        const store = useStore();

        const messages = ref([]);
        const new_password = ref(null);
        const new_password_confirm = ref(null);
        const password_changed = ref(false);

        function submitForm() {
            if (new_password.value && new_password_confirm.value) {
                messages.value = [];
                store.dispatch("auth/passwordResetConfirm", {
                    uid: props.uid,
                    token: props.token,
                    new_password: new_password.value,
                    new_password_confirm: new_password_confirm.value
                })
                    .then(
                        () => {
                            password_changed.value = true;
                            setTimeout(function () {
                                router.replace({name: "login"});
                            }, 2000);
                        },
                        (error) => {
                            if (error.response && error.response.data && error.response.data.errors) {
                                for (let item in error.response.data.errors) {
                                    for (let msg of error.response.data.errors[item]) {
                                        for (let m of msg) {
                                            messages.value.push({severity: 'error', content: m});
                                        }
                                    }
                                }
                            }
                            else if (error.response && error.response.data && error.response.data.message) {
                                messages.value.push({severity: 'error', content: error.response.data.message});
                            }
                            else {
                                messages.value.push({severity: 'error', content: i18n.t("error_occured_during_processing")});
                            }
                        }
                    );
            }
        }

        onMounted(function () {
            document.body.classList.add("authentication-bg");
        });

        onUnmounted(function () {
            document.body.classList.remove("authentication-bg");
        });

        return {
            new_password,
            new_password_confirm,
            password_changed,
            submitForm,
            messages
        };
    },
};
</script>

<style lang="css" scoped>
.password-reset p, .password-reset li {
    line-height: 1.5;
    color: #98a6ad;
}
</style>
