<template>
    <div class="not-found">
        <div class="container">
            <div class="p-d-flex p-jc-center">
                <div class="p-col p-md-8 p-lg-6 p-xl-5">
                    <Card class="p-mb-4">
                        <template #content class="p-p-4">
                            <div class="p-d-flex p-jc-center">
                                <img
                                    src="@/assets/theme/images/logo-login.png"
                                    class="logo-login p-m-6"
                                    alt="logo-login"
                                />
                            </div>
                            <h2 class="p-text-center" style="margin-bottom: 40px;">{{ $t('page_not_found') }}</h2>
                            <div class="p-pl-5 p-pr-5">
                                <p class="p-text-justify">{{ $t('page_not_found_text') }}</p>
                            </div>
                        </template>
                    </Card>
                    <div class="p-fluid p-text-center">
                        <router-link :to="{name: 'home'}" class="forgot-link">{{ $t("home") }}</router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { onMounted, onUnmounted } from "vue";
import Card from "primevue/card";

export default {
    name: "NotFoundView",
    components: {
        Card
    },
    setup() {
        onMounted(function () {
            document.body.classList.add("authentication-bg");
        });
        onUnmounted(function () {
            document.body.classList.remove("authentication-bg");
        });
    },
};
</script>

<style lang="css" scoped>
.not-found p {
    line-height: 1.5;
    color: var(--text-color);
}
</style>

