
<template>
    <div class="p-mt-2 p-d-flow-root">
        <Button v-if="isShopping && (data.value.status.id == 0 || data.value.status.id == -1)" :label="$t('cancel_this_cart')" class="p-button-danger p-float-left p-mr-2" @click="cancelCart()" />
        <Button v-if="data.value.user_id && kycUserDelete" :label="$t('reset_user_identity')" class="p-button-danger p-float-left p-mr-2" @click="resetUID()" />
        <Button v-if="data.value.status.id == 1 || data.value.status.id == 2" :label="$t('look_for_corresponding_transactions')" class="p-button-primary p-float-right p-ml-2" @click="lookForTransaction()" />
    </div>
</template>

<script>
import Button from 'primevue/button';

import {useStore} from "vuex";
import {useI18n} from "vue-i18n";
import {useRouter} from "vue-router";
import settings from "@/settings";
import { showError, updateMessage } from "@/mixins/message";

/*
*  @group Embeded
*  An embeded that display a set of buttons for a Cart (intented to be used in DetailView)
*/
export default {
    name: 'CartTransaction',
    props: {
        // data of object currently displayed in the `DetailView`
        'data': {
            type: Object,
            required: true,
        },
        // list of errors to show
        'messages': {
            type: Object,
            required: true,
        }
    },
    components: {
        Button,
    },
    setup(props) {
        const store = useStore();
        const i18n = useI18n();
        const router = useRouter();
        const isShopping = store.getters['schema/isShopping'];
        const kycUserDelete = !!settings.PLATFORM.KYC_USER_DELETE;

        function lookForTransaction() {
            router.push({ name: 'transaction-list', query: { barcode: props.data.value.barcode } });
        }

        function resetUID() {
            let payload = {};
            payload.url = settings.BASE_URL + '/cashboard/user/' + props.data.value.user_id + '/'
            store.dispatch('data/deleteModelObject', payload).then(
                () => {
                    updateMessage(props.messages, 'success', i18n.t("user_identity_successfuly_reset"));
                    router.push({ name: 'cart-list'});
                },
                (error) => {
                    showError(error, props.messages, i18n);
                }
            );
        }

        function cancelCart() {
            let payload = {};
            payload.url = store.getters["schema/getApiUrl"]('cart') + props.data.value.id + '/';
            store.dispatch('data/deleteModelObject', payload).then(
                () => {
                    updateMessage(props.messages, 'success', i18n.t("cart_successfuly_canceled"));
                    router.push({ name: 'cart-list'});
                },
                (error) => {
                    showError(error, props.messages, i18n);
                }
            );
        }

        return {
            lookForTransaction,
            cancelCart,
            isShopping,
            kycUserDelete,
            resetUID,
        }

    }
}
</script>
