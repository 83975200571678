const fr_translations = {
    dashboard: 'Tableau de bord',
    transaction: 'Transaction',
    transactions: 'Transactions',
    cart: 'Panier',
    carts: 'Paniers',
    pos: 'Point de vente',
    Pos: 'Points de vente',
    pointofsale: 'Point de vente',
    map: 'Carte',
    administration: 'Administration',
    settings: 'Paramètres',
    username: 'Nom d\'utilisateur',
    password: 'Mot de passe',
    sign_in: 'Connexion',
    signing_in : 'Connexion..',
    forgot_password: 'Mot de passe oublié',
    page_not_found: 'Page introuvable',
    page_not_found_text: 'Nous sommes désolé mais la page que vous avez demandée n\'existe pas. Si vous pensez qu\'il s\'agit d\'une erreur, veuillez contacter votre administrateur.',
    password_reset: 'Réinitialisation du mot de passe',
    email_address: 'Adresse e-mail',
    home: 'Accueil',
    submit: 'Soumettre',
    password_reset_done_msg_1: 'Nous vous avons envoyé par courriel les instructions pour changer de mot de passe, pour autant qu\'un compte existe avec l\'adresse que vous avez indiquée. Vous devriez recevoir rapidement ce message.',
    password_reset_done_msg_2: 'Si vous ne recevez pas de message, vérifiez que vous avez saisi l\'adresse avec laquelle vous vous êtes enregistré et contrôlez votre dossier de pourriels.',
    maintenance: 'Maintenance',
    site_unavailable: 'Site indisponible',
    site_unavailable_text: 'Le site est temporairement inaccessible en raison d’activités de maintenance. Vous pouvez essayer de vous connecter à nouveau dans quelques minutes.',
    thanks_understanding: 'Merci de votre compréhension.',
    no_opening_hours: 'Horaires d\'ouverture non disponibles',
    changes_successfuly_saved: "Modifications enregistrées",
    no_change_to_save: "Aucune modification à enregistrer.",
    user_identity_successfuly_reset: "Identité de l'utilisateur réinitalisée",
    cart_successfuly_canceled: "Panier annulé",

    unable_to_login: 'Impossible de se connecter',
    error_occured_during_processing :"Une erreur est survenue lors du traitement de votre requête.",
    unable_to_connect_to_server : "Impossible de contacter le serveur",
    unknown_error_occured_while_connecting : "Une erreur inconnue est survenue lors de la tentative de connexion.",
    unknown_error : "Erreur inconnue",
    error_occured_while_loading_app : "Une erreur est survenue lors du chargement de l'application.",
    error_occured_while_loading_pos : "Une erreur est survenue lors du chargement des points de vente.",
    no_data_available: 'Aucune données disponibles',
    new_password: 'Nouveau mot de passe',
    new_password_confirm: 'Confirmation du nouveau mot de passe',
    update_password: 'Modifier le mot de passe',
    password_validation_1: 'Votre mot de passe ne peut pas trop ressembler à vos autres informations personnelles.',
    password_validation_2: 'Votre mot de passe ne peut pas être un mot de passe couramment utilisé.',
    password_validation_3: 'Un mot de passe doit contenir au moins 1 majuscule, 1 minuscule, 1 chiffre, 1 caractère spécial et sa longueur doit être de 12 caractères.',
    password_reset_complete_msg: 'Votre mot de passe a été défini. Vous pouvez vous reconnecter.',

    dayNames: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
    dayNamesShort: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
    dayNamesMin: ["Su","Mo","Tu","We","Th","Fr","Sa"],
    monthNames: ["TEST","February","March","April","May","June","July","August","September","October","November","December"],
    monthNamesShort: ["Jan", "Feb", "Mar", "Apr", "May", "Jun","Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],

    search: "Rechercher",
    export: "Exporter",
    reset: 'Réinitialiser',
    save: 'Sauvegarder',
    delete: 'Supprimer',

    from: 'Début',
    to: 'Fin',
    min: 'Min',
    max: 'Max',

    no_results : 'Aucun résultat',
    showing_x_lines_of_x: 'Affichage de {0} resulats sur {1}',
    deposit: 'Dépôt',
    search_address: 'Rechercher une adresse',
    search_pointcash: 'Rechercher un relais Point Cash Services',
    day: "Jour",
    monday: "Lundi",
    tuesday: "Mardi",
    wednesday: "Mercredi",
    thursday: "Jeudi",
    friday: "Vendredi",
    saturday: "Samedi",
    sunday: "Dimanche",
    schedules: "Horaires",
    schedules_information_only: "Horaires donnés à titre indicatif",
    best_navigation: "Cliquez ci-dessous pour obtenir l'itinéraire le plus rapide pour vous rendre au point de vente sélectionné.",
    route: 'Itinéraire',
    map_loading: 'Chargement des points de vente ...',
    no_geoloc: 'Afin de trouver les points de vente les plus proches de votre position, veuillez entrer une adresse dans la barre d\'adresse ci-dessus.',

    remove_route: 'Supprimer l\'itinéraire',

    // CartSchedule.vue
    cart_schedules : "Échéancier de paiement",
    valid_from: "Valide à partir de",
    valid_until: "Valide jusqu'au",
    amount: "Montant",
    status: "Statut",

    //CartTransaction.vue
    look_for_corresponding_transactions: "Rechercher les transactions correspondantes",
    cancel_this_cart: "Annuler ce panier",

    //KycUserDelete.vue
    reset_user_identity: "Supprimer l'identité de l'utilisateur",

    //OpeningHours.vue
    schedules_are_for_information_only : "Heures d'ouverture à titre indicatif uniquement",
    opening_hours : "Heures d'ouverture",
    week_day : "Jour de la semaine",

    http : {
        bad_request : "Les données envoyées ne sont pas conformes",
        unauthorized : "L'accès à cette ressource nécéssite une authentification",
        forbidden : "Votre compte ne possède pas les droits suffisants pour effectuer cette opération",
        internal_server_error : "Le serveur a rencontré une erreur lors du traitement de votre requête",
    }

}

const prime_fr_translations = {
    dayNames: ["Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"],
    dayNamesShort: ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"],
    dayNamesMin: ["Di", "Lu", "Ma", "Me", "Je", "Ve", "Sa"],
    monthNames: ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre" ],
    monthNamesShort: ["Jan", "Fev", "Mar", "Avr", "Mai", "Jun", "Jui", "Aoû", "Sep", "Oct", "Nov", "Dec" ],
    today: 'Aujourd\'hui',
    weekHeader: 'Sem',
    firstDayOfWeek: 1,
    dateFormat: 'dd/mm/yy',
}

export {
    fr_translations,
    prime_fr_translations
};
