function showError(error, messages, i18n) {
    messages.value.clear();
    if (error.response && error.response.data && error.response.data.errors) {
        for (let item in error.response.data.errors) {
            for (let msg of error.response.data.errors[item]) {
                let m = item !== '__all__' ? item.toTitleCase() + ': ' + msg : msg;
                messages.value.push({severity: 'error', content: m});
            }
        }
    }
    else if (error.response && error.response.data && error.response.data.message) {
        messages.value.push({severity: 'error', content: error.response.data.message});
    }
    else {
        messages.value.push({severity: 'error', content: i18n.t("error_occured_during_processing")});
    }
}

function updateMessage(messages, severity, text) {
    messages.value.push({severity: severity, content: text});
}

export {
    showError,
    updateMessage
}
