<template>
    <div class="wrapper">
        <Topbar @toggle-sidebar="toggleSidebar"></Topbar>
        <Sidebar :class="{ 'sidebar-active': sidebarActive }"></Sidebar>
        <div class="layout-mask" @click="closeSidebar" :class="{ 'layout-mask-active': sidebarActive }"></div>
        <div class="content" data-cy="content">
            <div>
                <div class="content-section">
                    <!-- Contains the page body -->
                    <slot></slot>
                </div>
            </div>
            <Footer></Footer>
        </div>
    </div>
</template>

<script>
import { ref } from "vue";
import Topbar from "@/components/layouts/Topbar";
import Sidebar from "@/components/layouts/Sidebar";
import Footer from "@/components/layouts/Footer";

/*
*  @group Layouts
*  BaseContent is the base of all page that contains `Topbar`, `Sidebar`, `Footer` and page content
*/
export default {
    name: "BaseContent",
    components: {
        Topbar,
        Sidebar,
        Footer
    },
    setup() {

        const sidebarActive = ref(false);

        function toggleSidebar() {
            sidebarActive.value = !sidebarActive.value;
        }

        function closeSidebar() {
            sidebarActive.value = false;
        }

        return {
            sidebarActive,
            toggleSidebar,
            closeSidebar
        }
    }
}
</script>

<style lang="scss">
.content {
    margin-left: 250px;
    padding-top: 69px;
}
.content .content-section {
    background-color: #efefef;
    color: var(--text-color);
    padding: 1.5rem;
}
@media screen and (max-width: 960px) {
    .content {
        margin-left: 0;
    }
    .layout-mask {
        background-color: rgba(0,0,0,.1);
    }
    .layout-mask.layout-mask-active {
        z-index: 1007;
        width: 100%;
        height: 100%;
        position: fixed;
        top: 69px;
        left: 0;
        background-color: rgba(0,0,0,.4);
        -webkit-transition: background-color .5s;
        transition: background-color .5s;
    }
}
</style>
