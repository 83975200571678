<template>
  <router-view/>
</template>

<script>
import axios from "axios";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { useStore } from 'vuex';

export default {
    name: 'App',
    setup() {
        const { t } = useI18n();
        const router = useRouter();
        const store = useStore();

        let timer = null;
        store.dispatch('auth/load');
        store.dispatch('schema/load');

        axios.interceptors.response.use(function (response) {
            if ((router.currentRoute.value.meta && router.currentRoute.value.meta.loginRequired) || router.currentRoute.value.name === 'login') {
                const tokenLifeTime = store.getters['schema/tokenLifetime'];
                const expiresIn = tokenLifeTime * 59 * 1000;
                const expirationDate = new Date().getTime() + expiresIn;
                store.commit('auth/setTokenExpiration', expirationDate);
                localStorage.setItem('tokenExpiration', expirationDate.toString());
                if (timer) {
                    clearTimeout(timer);
                }
                timer = setTimeout(function () {
                    if (!store.getters['auth/isAuthenticated']) {
                        store.dispatch('auth/logout');
                        router.push({name :'login', query: {'next': router.currentRoute.value.path}});
                    }
                }, expiresIn);
            }
            return response;
        }, function (error) {
            if (error.response) {
                if (error.response.status >= 502) {
                    router.push({name :'maintenance'});
                }
            }
            else {
                router.push({name :'maintenance'});
            }
            return Promise.reject(error);
        });

        router.beforeEach((to, from, next) => {
            if (to.meta && to.meta.title) {
                let title = t(to.meta.title);
                if (to.params && to.params.id) {
                    title += ': #' + to.params.id;
                }
                document.title = title;
            }
            if (to.meta && to.meta.loginRequired && !store.getters['auth/isAuthenticated'])  {
                router.push({name :'login', query: {'next': to.path}});
            }
            if (to.meta && to.meta.isShopping && !store.getters['schema/isShopping'])  {
                router.push('/notfound');
            }
            next();
        });
    }
}

</script>
