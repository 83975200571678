<template>
    <BaseContent>
        <h3 class="page-title p-mt-0">{{ $t('pos') }}: #{{ id }}</h3>
        <DetailView :model="model" :id="id" :embeded="embeded"></DetailView>
    </BaseContent>
</template>

<script>
import BaseContent from "@/components/layouts/BaseContent";
import DetailView from "@/components/layouts/DetailView";
import DetailMap from "@/components/embeded/DetailMap";
import OpeningHours from "@/components/embeded/OpeningHours";

/*
*  @group DetailView
*  Detail view for `Pos` (Point of Sale)
*/
export default {
    name: 'PosDetailView',
    components: {
        BaseContent,
        DetailView
    },
    props: {
        // id of the object to display
        'id': {
            type: [String, Number],
            required: true,
        },
    },
    setup() {
        const embeded = [
            {
                embed: DetailMap,
                weight: -1
            },
            {
                embed: OpeningHours,
                weight: 1
            }
        ];

        return {
            embeded,
            model: 'pointofsale'
        }
    }
}
</script>
