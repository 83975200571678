<template>
    <Dropdown
        v-model="selectedValue"
        ref="el"
        :options="suggestionsList"
        optionLabel="label"
        optionValue="value"
        :filter="true"
        :placeholder="placeholder"
        :showClear="true"
        @filter="filter"
        @change="itemSelect"
        @show="onShow">
    </Dropdown>
</template>

<script>

import { onMounted, computed, ref, watch } from "vue";
import Dropdown from 'primevue/dropdown';
import {useStore} from "vuex";

/*
*  @group Widgets
*  AutoComplete is used to generate a field that handle user text input with suggestions of choices
*/
export default {
    name: "SchemaAutoComplete",
    props: {
        // `v-model` attribute
        modelValue : {
            type: Object,
            required: false,
        },
        // Url to call for suggestions search
        suggestionsUrl: {
            type: String,
            required: true
        },
        // Placeholder when value empty
        placeholder: {
            type: String,
            required: false,
        }
    },
    emits: ['update:modelValue'],
    components: {
        Dropdown,
    },
    setup(props, { emit }) {
        const store = useStore();
        const el = ref(null);
        let scrollTop = 0;
        const nextPage = ref(undefined);
        const loading = ref(false);
        const suggestionsList = ref([]);
        const selectedValue = computed({
            get: function() {
                return props.modelValue?.id;
            },
            set: function() {
                // value of v-model updated with itemSelect()
            }
        });

        watch(() => props.modelValue, (current) => {
            if (current && current.id && suggestionsList.value.filter(function(e) { return e.value === current?.id }).length === 0) {
                suggestionsList.value.unshift({label:current?.text, value:current?.id});
            }
        });

        const fetchChoices = function(payload, overwrite=false, scroll=true) {
            loading.value = true;
            store.dispatch('data/getSuggestions', payload).then(
                (response) => {
                    if (overwrite) {
                        suggestionsList.value = [];
                    }
                    suggestionsList.value.push(...response.data.results);
                    if (selectedValue.value) {
                        let selectedValueCount = suggestionsList.value.filter(function(e) { return e.value ===  props.modelValue.id }).length
                        if (selectedValueCount === 0) {
                            suggestionsList.value.unshift({label:props.modelValue.text, value:props.modelValue.id});
                        }
                        else if (selectedValueCount > 1) {
                            suggestionsList.value.shift();
                        }
                    }

                    if (el.value.overlay && scroll) {
                        setTimeout(function () {
                            el.value.overlay.querySelector(".p-dropdown-items-wrapper").scrollTo(0, scrollTop);
                        });
                    }
                    nextPage.value = response.data.next;
                    loading.value = false;
                },
                (error) => {
                    console.log(error);
                    loading.value = false;
                }
            );
        }

        const itemSelect = function(event) {
            let text = null;
            for (let i = 0; i < suggestionsList.value.length; i++) {
                if (suggestionsList.value[i].value === event.value) {
                    text = suggestionsList.value[i].label;
                }
            }
            emit('update:modelValue', {id:event.value, text:text});
        }

        const filter = function() {
            fetchChoices(
                {
                    query:el.value.filterValue,
                    url:props.suggestionsUrl,
                },
                true,
                false
            )
        }

        const handleChoiceScroll = function() {
            let scroll = this.scrollHeight - (this.scrollTop + this.clientHeight);
            if (!loading.value && scroll < 100 && nextPage.value !== null) {
                scrollTop = this.scrollTop;
                fetchChoices(
                    {
                        query:this.filterValue,
                        url:nextPage.value,
                    }
                );
            }
        }

        onMounted(function () {
            fetchChoices(
                {
                    url:props.suggestionsUrl,
                },
                true,
            )
        });

        const onShow = function() {
            // Add scroll event listener to dropdown overlay for next pages loading
            let element = el.value.overlay.querySelector(".p-dropdown-items-wrapper");
            element.onscroll = handleChoiceScroll;
            let highlight = el.value.overlay.querySelector(".p-highlight");
            let scrollSize = highlight ? highlight.offsetTop - 64 : 0
            element.scrollTo(0, scrollSize);
        }

        return {
            suggestionsList,
            selectedValue,
            fetchChoices,
            itemSelect,
            onShow,
            filter,
            el,
        }
    }
}
</script>
<style scoped>
.p-autocomplete-input {
    width: 100%;
}
</style>
