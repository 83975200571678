<template>
    <div class="topbar p-d-flex p-as-end">
        <div class="topbar-button menu-button primary-box p-ripple p-mr-auto" v-ripple @click="toggleSidebar"><i class="fal fa-bars"></i></div>
        <div class="topbar-button primary-box p-ripple" @click="toggleMenu" aria-haspopup="true" aria-controls="overlay_menu" v-ripple><i class="fal fa-cog"></i></div>
        <Menu ref="menu" :model="menu_items" :popup="true" />
        <div class="topbar-button primary-box p-ripple" @click="logout"><i class="fal fa-power-off" v-ripple></i></div>
    </div>
</template>

<script>
import { ref } from 'vue'
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n'
import Menu from 'primevue/menu';
import Ripple from 'primevue/ripple';
import settings from "@/settings";
import {useStore} from "vuex";

// @group Layouts
// Topbar used in all pages. Emits `toggle-sidebar` when show / hide sidebar button is pressed.
export default {
    name: "Topbar",
    components: {
        Menu
    },
    directives: {
        'ripple': Ripple
    },
    emits: ['toggle-sidebar'],
    setup(props, context) {

        const i18n = useI18n();
        const router = useRouter();
        const store = useStore();
        const menu = ref(null);

        const menu_items = [
            {
                label: i18n.t('settings'),
                items: [
                    {
                        label: i18n.t('administration'),
                        icon: 'fas fa-database',
                        url: settings.ADMIN_URL,
                        target: '_blank'
                    },
                    {
                        label: i18n.t('password_reset'),
                        icon: 'fas fa-lock-open',
                        to: { name: 'password-reset' }
                    }
                ]
            }
        ]

        function toggleMenu(event) {
            menu.value.toggle(event);
        }

        function toggleSidebar() {
            context.emit('toggle-sidebar');
        }

        function logout() {
            store.dispatch('auth/logout');
            router.replace({ name: 'login' });
        }

        return {
            menu,
            menu_items,
            toggleMenu,
            toggleSidebar,
            logout
        }
    }
}
</script>

<style lang="scss">
@import "src/assets/theme/scss/variable";

.topbar {
    height: 70px;
    background-color: $topbar-color;
    -webkit-box-shadow: 0 0 4px rgba(0, 0, 0, .25);
    box-shadow: 0 0 4px rgba(0, 0, 0, .25);
    border-bottom: 1px solid $topbar-color;
    -webkit-transition: -webkit-transform .4s cubic-bezier(.05,.74,.2,.99);
    transition: -webkit-transform .4s cubic-bezier(.05,.74,.2,.99);
    transition: transform .4s cubic-bezier(.05,.74,.2,.99),-webkit-transform .4s cubic-bezier(.05,.74,.2,.99);
    padding-left: 250px;
    display: flex;
    justify-content: flex-end;
    width: 100%;
    position: fixed;
    z-index: 1009;
}
.topbar .topbar-button {
    color: $topbar-button-color;
    width: 70px;
    height: 70px;
    line-height: 70px;
    text-align: center;
    -webkit-transition: background-color .2s;
    transition: background-color .2s;
    cursor: pointer;
}
.topbar .menu-button {
    display: none;
}
.topbar .topbar-button i {
    font-size: 24px;
    line-height: inherit;
}
.topbar .topbar-button:hover {
    background-color: $topbar-button-hover-color;
}
.topbar .p-menu {
    font-size: .875rem;
}
@media screen and (max-width: 960px) {
    .topbar {
        padding: 0;
    }
    .topbar .menu-button {
        display: block;
    }
}
</style>
