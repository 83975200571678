<template>
    <div class="footer">
        <div class="footer-left">
            <span>2019 - 2021 © Cashway</span>
        </div>
        <div class="footer-right">
            <a href="https://www.cashway.fr/contactez-nous/" target="_blank" title="www.cashway.fr">Contactez‑nous</a>
        </div>
    </div>
</template>

<script>
// @group Layouts
// Footer user in BaseContent for all pages
export default {
    name: "Footer"
}
</script>

<style lang="scss">
.footer {
    padding: 1rem 1.5rem 1rem 1.5rem;
    background-color: var(--surface-a);
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: #98a6ad;
}
.footer a {
    color: #98a6ad;
    font-weight: 500;
}
.footer a:hover {
    color: var(--text-color);
}
</style>
