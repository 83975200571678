import {createRouter, createWebHistory} from 'vue-router';
import DashboardView from '@/views/DashboardView';
import LoginView from '@/views/LoginView';
import MapView from '@/views/MapView';
import TransactionListView from '@/views/TransactionListView'
import PosListView from "@/views/PosListView";
import NotFoundView from '@/views/NotFoundView';
import TransactionDetailView from "@/views/TransactionDetailView";
import PosDetailView from "@/views/PosDetailView";
import CartListView from "@/views/CartListView"
import CartDetailView from "@/views/CartDetailView";
import PasswordResetView from "@/views/PasswordResetView";
import PasswordResetConfirmView from "@/views/PasswordResetConfirmView";
import MaintenanceView from "@/views/MaintenanceView";


const routes = [
    {
        path: '/',
        redirect: {
            name: 'home',
            loginRequired: true,
        }
    },
    {
        path: '/login',
        name: 'login',
        component: LoginView,
        meta: {
            title: 'sign_in'
        }
    },
    {
        path: '/home',
        name: 'home',
        component: DashboardView,
        meta: {
            title: 'dashboard',
            loginRequired: true,
        }
    },
    {
        path: '/map',
        name: 'map',
        component: MapView,
        meta: {
            title: 'map',
            loginRequired: true,
        }
    },
    {
        path: '/transactions',
        name: 'transaction-list',
        component: TransactionListView,
        meta: {
            title: 'transactions',
            loginRequired: true,
        }
    },
    {
        path: '/transactions/:id',
        name: 'transaction-detail',
        props: true,
        component: TransactionDetailView,
        meta: {
            title: 'transaction',
            loginRequired: true,
        }
    },
    {
        path: '/carts',
        name: 'cart-list',
        component: CartListView,
        meta: {
            title: 'carts',
            loginRequired: true,
            isShopping: true
        }
    },
    {
        path: '/carts/:id',
        name: 'cart-detail',
        props: true,
        component: CartDetailView,
        meta: {
            title: 'cart',
            loginRequired: true,
            isShopping: true
        }
    },
    {
        path: '/pos',
        name: 'pos-list',
        component: PosListView,
        meta: {
            title: 'Pos',
            loginRequired: true,
        }
    },
    {
        path: '/pos/:id',
        name: 'pos-detail',
        props: true,
        component: PosDetailView,
        meta: {
            title: 'pos',
            loginRequired: true,
        }
    },
    {
        path: '/password/reset',
        name: 'password-reset',
        component: PasswordResetView,
        meta: {
            title: 'password_reset',
        }
    },
    {
        path: '/password/reset/:uid/:token',
        name: 'password-reset-confirm',
        component: PasswordResetConfirmView,
        props: true,
        meta: {
            title: 'password_reset',
        }
    },
    {
        path: '/maintenance',
        name: 'maintenance',
        component: MaintenanceView,
        meta: {
            title: 'maintenance',
        }
    },
    {
        path: '/:notFound(.*)',
        component: NotFoundView,
        meta: {
            title: 'page_not_found'
        }
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior () {
        return {left: 0, top: 0}
    },
});

export default router;
