<template>
    <BaseContent>
        <h3 class="page-title p-mt-0">{{ $t('cart') }}: #{{ id }}</h3>
        <DetailView :model="model" :id="id" :embeded="embeded"></DetailView>
    </BaseContent>
</template>

<script>
import BaseContent from "@/components/layouts/BaseContent";
import DetailView from "@/components/layouts/DetailView";
import CartSchedule from "@/components/embeded/CartSchedule.vue";
import CartTransaction from "@/components/embeded/CartTransaction.vue";

/*
*  @group DetailView
*  Detail view for `Carts`
*/
export default {
    name: 'CartDetailView',
    components: {
        BaseContent,
        DetailView
    },
    props: {
        // id of the object to display
        'id': {
            type: [String, Number],
            required: true,
        },
    },
    setup() {
        const embeded = [
            {
                embed: CartSchedule,
                weight: 1,
            },
            {
                embed: CartTransaction,
                weight: 2,
            },
        ];
        return {
            model: 'cart',
            embeded,
        }
    }
}
</script>
